.book-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #E4D7C5;
  border: 1px solid #B8A08A;
  border-radius: 5px;
  cursor: pointer;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;
  height: 60vh;
  width: 18vw;
  margin: 1vw;
  padding: 1vh;
}

.book-items {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 99%;
  width: 99%;
}

.book-container:hover {
  transform: scale(1.05);
}

.book-url {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 55%;
  width: 60%;
}

.book-title {
  font-weight: 600;
}

.book-rating {
  font-weight: 500;
}

.book-star {
  height: 12px;
  width: 12px;
  fill: darkorange;

}

/* folded phones */
@media only screen and (max-width: 300px) {
  .book-container {
    width: 40vw;
    height: 140vw;
    margin: 1vw;
  }

  .book-url {
    width: 100%;
    height: 45%;
  }
}

/* regular phones */
@media only screen and (min-width: 301px) and (max-width: 400px) {
  .book-container {
    width: 40vw;
    height: 105vw;
    margin: 1vw;
  }

  .book-url {
    width: 100%;
    height: 56%;
  }
}

/* wider phones */
@media only screen and (min-width: 401px) and (max-width: 450px) {
  .book-container {
    width: 40vw;
    height: 95vw;
    margin: 1vw;
  }

  .book-url {
    width: 100%;
    height: 60%;
  }
}

/* tablet or rotated phones */
@media only screen and (min-width: 451px) and (max-width: 600px) {
  .book-container {
    width: 26vw;
    height: 70vw;
    margin: 1vw;
  }

  .book-url {
    width: 100%;
    height: 54%;
  }
}

/* wider tablet */
@media only screen and (min-width: 601px) and (max-width: 700px) {
  .book-container {
    width: 20vw;
    height: 56vw;
    margin: 1vw;
  }

  .book-url {
    width: 100%;
    height: 54%;
  }
}

/* rotated small tablet */
@media only screen and (min-width: 701px) and (max-width: 800px) {
  .book-container {
    width: 19vw;
    height: 52vw;
    margin: 1vw;
  }

  .book-url {
    width: 100%;
    height: 54%;
  }
}

/* wide tablet */
@media only screen and (min-width: 801px) and (max-width: 850px) {
  .book-container {
    width: 18vw;
    height: 46vw;
    margin: 1vw;
  }

  .book-url {
    width: 100%;
    height: 54%;
  }
}

/* iphone xr */
@media only screen and (min-width: 851px) and (max-width: 900px) {
  .book-container {
    width: 16vw;
    height: 46vw;
    margin: 1vw;
  }

  .book-url {
    width: 100%;
    height: 54%;
  }
}

/* rotated iphone pro max */
@media only screen and (min-width: 901px) and (max-width: 1000px) {
  .book-container {
    width: 16vw;
    height: 43vw;
    margin: 1vw;
  }

  .book-url {
    width: 100%;
    height: 54%;
  }
}

/* larger tablets */
@media only screen and (min-width: 1001px) and (max-width: 1100px) {
  .book-container {
    width: 14vw;
    height: 40vw;
    margin: 1vw;
  }

  .book-url {
    width: 100%;
    height: 56%;
  }
}

/* larger screens */
@media only screen and (min-width: 1101px) and (max-width: 1400px) {
  .book-container {
    width: 180px;
    height: 420px;
    margin: 10px;
    font-size: 18px;
  }

  .book-url {
    width: 100%;
    height: 58%;
  }
}

/* regular */
@media only screen and (min-width: 1401px) {
  .book-container {
    width: 180px;
    height: 420px;
    margin: 10px;
    font-size: 16px;
  }

  .book-url {
    width: 160px;
    height: 240px;
  }
}