.book-search {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin-top: 8%;
}

/* folded phones */
@media only screen and (max-width: 300px) {
    .book-search {
        width: 60%;
    }
}

/* regular phones */
@media only screen and (min-width: 301px) and (max-width: 400px) {
    .book-search {
        width: 90%;
    }
}

/* wider phones */
@media only screen and (min-width: 401px) and (max-width: 450px) {
    .book-search {
        width: 98%;
    }
}

/* tablet or rotated phones */
@media only screen and (min-width: 451px) and (max-width: 600px) {
    .book-search {
        width: 98%;
    }
}

/* wider tablet */
@media only screen and (min-width: 601px) and (max-width: 700px) {
    .book-search {
        width: 98%;
    }
}

/* rotated small tablet */
@media only screen and (min-width: 701px) and (max-width: 800px) {
    .book-search {
        width: 98%;
    }
}

/* wide tablet */
@media only screen and (min-width: 801px) and (max-width: 850px) {
    .book-search {
        width: 98%;
    }
}

/* iphone xr */
@media only screen and (min-width: 851px) and (max-width: 900px) {
    .book-search {
        width: 98%;
    }
}

/* rotated iphone pro max */
@media only screen and (min-width: 901px) and (max-width: 1000px) {
    .book-search {
        width: 98%;
    }
}

/* larger tablets */
@media only screen and (min-width: 1001px) and (max-width: 1100px) {
    .book-search {
        width: 98%;
    }
}

/* larger screens */
@media only screen and (min-width: 1101px) and (max-width: 1400px) {
    .book-search {
        width: 98%;
    }
}

/* regular */
@media only screen and (min-width: 1401px) {
    .book-search {
        width: 160px;
    }
}